myElement = document.querySelector("header")
headroom  = new Headroom(myElement,
  offset: 150
  classes: 
    notTop: "header--not-top"
)
headroom.init()

document.addEventListener 'DOMContentLoaded', () ->

	$('.to-top').on 'click', (e) ->
		e.preventDefault()
		offset = 0
		scrollPage = new SmoothScroll()
		scrollPage.animateScroll(document.querySelector($(this).attr('href')), 0, {updateURL: false, offset: offset, speed: 200, speedAsDuration: true})



	infoModalEl = document.getElementById('infoModal')
	infoModal 	= bootstrap.Modal.getOrCreateInstance(infoModalEl)

	infoModalEl.addEventListener('show.bs.modal', (event) ->
		
		button 	= event.relatedTarget
		title 	= button.getAttribute('data-bs-title')
		text 		= button.getAttribute('data-bs-text')

		modalTitle = infoModalEl.querySelector('.modal-title')
		modalTitle.textContent = title

		modalText = infoModalEl.querySelector('.modal-text')
		modalText.innerHTML = text

		infoModal.handleUpdate()
	)

	if $('.team-slider').length > 0

		$('.team-slider').each (i, e) ->
			$this = $(e)

			$this.slick
				infinite: true
				speed: 500
				cssEase: 'linear'
				swipe: true
				useCSS: false
				dots: false
				arrows: false
				slidesToShow: 3
				slidesToScroll: 3
				autoplay: false
				autoplaySpeed: 6000
				dotsClass: 'slider-dots mt-5'
				customPaging: (slider, i) -> 
					return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
					return "<span class='slider-dots-item'></span>"
				responsive: [
					{
						breakpoint: 768
						settings:
							slidesToShow: 1
							slidesToScroll: 1	
							autoplay: true
							centerMode: true
							centerPadding: '72px'
							dots: true
					}
					{
						breakpoint: 576
						settings:
							slidesToShow: 1
							slidesToScroll: 1	
							autoplay: true
							centerMode: true
							centerPadding: '36px'
							dots: true
					}
				]

	if $('.img-slider').length > 0

		$('.img-slider').each (i, e) ->
			$this = $(e)

			$this.slick
				infinite: true
				speed: 500
				cssEase: 'linear'
				swipe: true
				useCSS: false
				dots: true
				arrows: false
				centerMode: true
				variableWidth: true
				centerPadding: '36px'
				slidesToShow: 1
				slidesToScroll: 1	
				autoplay: true
				autoplaySpeed: 6000
				dotsClass: 'slider-dots mt-5'
				customPaging: (slider, i) -> 
					return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
					return "<span class='slider-dots-item'></span>"
				responsive: [
					{
						breakpoint: 576
						settings:
							variableWidth: false
					}
				]


	if $('.slick-articles-thumbs').length > 0

		$('.slick-articles-thumbs').each (i, e) ->
			$this = $(e)

			$this.slick
				infinite: true
				speed: 500
				fade: true
				cssEase: 'linear'
				swipe: false
				useCSS: false
				asNavFor: '.slick-articles-text'
				dots: false
				arrows: false

	if $('.slick-articles-text').length > 0

		$('.slick-articles-text').each (i, e) ->
			$this = $(e)

			$this.slick
				infinite: true
				speed: 500
				fade: true
				cssEase: 'linear'
				swipe: false
				useCSS: false
				asNavFor: '.slick-articles-thumbs'
				dots: true
				arrows: true
				dotsClass: 'slider-dots'
				customPaging: (slider, i) -> 
					return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
					return "<span class='slider-dots-item'></span>"
				appendArrows: '.slick-control-arrows'
				appendDots: '.slick-control-dots'
				prevArrow: '<div class="slider-control slider-control-prev"></div>'
				nextArrow: '<div class="slider-control slider-control-next"></div>'


	if $('.news-slider').length > 0

		$('.news-slider').each (i, e) ->
			$this = $(e)

			$this
				.on 'init', (event, slick) ->
					$('.news').find('.current-slide').html(1)
					$('.news').find('.total-slide').html(slick.slideCount)
				.on 'beforeChange', (event, slick, currentSlide, nextSlide) ->
					$('.news').find('.current-slide').html(nextSlide + 1)
				.slick
					infinite: true
					speed: 500
					fade: true
					cssEase: 'linear'
					swipe: false
					useCSS: false
					dots: false
					arrows: true
					autoplay: true
					autoplaySpeed: 6000
					dotsClass: 'slider-dots'
					customPaging: (slider, i) -> 
						return false if slider.slideCount == 1 || slider.slideCount == slider.options.slidesToShow
						return "<span class='slider-dots-item'></span>"
					appendArrows: '.news-control'
					prevArrow: '<div class="slider-control slider-control-prev"></div>'
					nextArrow: '<div class="slider-control slider-control-next"></div>'



	mapElement = document.getElementById('lmap')

	if typeof(mapElement) != 'undefined' && mapElement != null

		init = ->
			icon = L.icon({
				iconUrl: '/user/themes/doctor/img/marker@2x.png',
				iconSize: [60, 96]
			})

			map = L.map('lmap', {
				center: [48.3089992, 14.0209952],
				zoom: 17 
			})

			L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
				subdomains:['mt0','mt1','mt2','mt3']
			}).addTo(map);

			L.marker([48.3089992, 14.0209952], {icon: icon}).addTo(map);

		init()